import * as React from "react";
import Layout from "../components/layout";
import * as styles from "./accommodation.module.css";

import accommodationJson from "../../content/accommodation.json";
import img from "../images/accom.png";

const MAP_SRC = "https://www.google.com/maps/d/embed?mid=19cw1XulQWfqXX3L4kzyplJ9ShrzMRa8&ehbc=2E312F";

const InfoPage = () => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const drawerClass = drawerOpen ? styles.listItemHidden : "";

    const handleDrawerClick = e => {
        e.preventDefault();
        setDrawerOpen(!drawerOpen);
    }
    return (
        <Layout>
            <div className={styles.title}>
                Accommodation
            </div>
            <div className={styles.container}>
                <div className={styles.illustration}>
                    <img src={img} />
                </div>
                <div className={styles.containerItem}>
                    There's not a huge amount of accommodation nearby, so we'd recommend booking somewhere
                    as soon as you can! We've compiled a list and a map below of the best options, but it's
                    also worth having a look on Airbnb.
                    <br /> <br />
                    For those who will be getting the train down on the day, we will organise some transport from
                    Billingshurst train station to the church (around 1.30pm, exact time TBC).
                    <br /> <br />
                    And if you fancy staying for the bbq on Sunday, then we'll also organise a lift back to the station
                    on Sunday afternoon too.
                </div>
            </div>
            <div className={styles.mapContainer}>
                <div className={styles.mapList}>
                    <button className={styles.drawerButton} onClick={handleDrawerClick}>
                        <div>Pub and hotels</div>
                        <div className={styles.drawerIcon}>
                            {drawerOpen ? "-" : "+"}
                        </div>
                    </button>
                    {accommodationJson.map(item => (
                        <div className={`${styles.listItem} ${drawerClass}`}>
                            <a href={item.Website} target="_blank">
                                {item.Pub}, {item.Where}
                            </a>
                        </div>
                    ))}
                </div>
                <div className={styles.map}>
                    <iframe src={MAP_SRC} />
                </div>
            </div>
        </Layout>
    );
};

export default InfoPage;

export const Head = () => <title>Accommodation | Bebe and Charlie</title>;
