// extracted by mini-css-extract-plugin
export var container = "accommodation-module--container--AjU1C";
export var containerItem = "accommodation-module--containerItem--3VqOa";
export var drawerButton = "accommodation-module--drawerButton--Qw3Za";
export var drawerIcon = "accommodation-module--drawerIcon--F0UjI";
export var illustration = "accommodation-module--illustration--2VtZd";
export var listItem = "accommodation-module--listItem--7iEVR";
export var listItemHidden = "accommodation-module--listItemHidden--euRPl";
export var map = "accommodation-module--map--IltVV";
export var mapContainer = "accommodation-module--mapContainer--dV+JM";
export var mapList = "accommodation-module--mapList--HFQJz";
export var title = "accommodation-module--title--5wtub";